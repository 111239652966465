<template>
  <v-row class="rowSmall" v-if="localCallGroup!==null">
    <v-col cols="4">
      <slot name="widget"/>
      <v-btn
          v-if="localCallGroup.userClient!==null"
          @click="$emit('close');$router.push('/user/client/'+localCallGroup.userClient.id+'/')"
          depressed block small
      >
        Профиль
      </v-btn>
      <v-btn
          @click="$emit('close');$router.push('/visit?'+visitLink())"
          depressed block small class="mt-2" color="primary"
      >
        Создать визит
      </v-btn>
      <v-btn
          @click="$emit('close');$router.push('/visit?'+visitLink()+'&type=waiting-list')"
          depressed block small class="mt-2" color="primary"
      >
        В лист ожидания
      </v-btn>
      <v-btn
          @click="$emit('close');$router.push('/visit?'+visitLink()+'&type=liveList')"
          depressed block small class="mt-2" color="primary"
      >
        В лист живой очереди
      </v-btn>
      <v-btn
          v-if="localCallGroup.callDirection===1 || localCallGroup.callDirection===2"
          @click="$emit('close');$router.push('/call/?phone='+(localCallGroup.callDirection===1 ? localCallGroup.fromPhoneNumber : localCallGroup.toPhoneNumber))"
          depressed block small class="mt-2"
      >
        Звонки с этим номером
      </v-btn>
      <v-btn
          v-if="localCallGroup.userClient!==null"
          @click="$emit('close');$router.push('/call/?userId='+localCallGroup.userClient.id)"
          depressed block small class="mt-2"
      >
        Звонки клиента
      </v-btn>
      <slot name="actions"/>
    </v-col>
    <v-col>
      <div class="caption mb-1">Звонок</div>
      <v-btn-toggle v-model="callType" @change="callTypeChange()" class="full-width mb-2">
        <v-btn small style="width: 50%">Клиентский<v-icon small v-if="callType===0" class="ml-2">mdi-check</v-icon></v-btn>
        <v-btn small style="width: 50%">Служебный<v-icon small v-if="callType===1" class="ml-2">mdi-check</v-icon></v-btn>
      </v-btn-toggle>
      <div v-if="callType===0">
        <div class="caption mb-1">Клиент</div>
        <v-btn-toggle v-model="callClientType" @change="callClientTypeChange()" class="full-width mb-4">
          <v-btn small style="width: 33%">Анон.перв.<v-icon small v-if="callClientType===0" class="ml-2">mdi-check</v-icon></v-btn>
          <v-btn small style="width: 33%">Анон.втор.<v-icon small v-if="callClientType===1" class="ml-2">mdi-check</v-icon></v-btn>
          <v-btn small style="width: 33%">Из базы<v-icon small v-if="callClientType===2" class="ml-2">mdi-check</v-icon></v-btn>
        </v-btn-toggle>
        <user-autocomplete-single
            v-if="callClientType===2"
            name="Клиент"
            :select="localCallGroup.userClient"
            :include="userClientRecoms"
            @update:select="userClientUpdate"
        />

        <autocomplete-single name="Цель звонка" :items="callTargets" @update:select="callTargetUpdate" :select.sync="callTarget">
          <template v-slot:no-data>
            <div class="caption text-center">Не найдено</div>
          </template>
          <template v-slot:item="{item}">
            <v-sheet max-width="189">
              <v-row no-gutters class="align-center">
                <v-col class="body-2 py-2 pr-2">
                  {{item.name}}
                </v-col>
                <v-col cols="auto">
                  <tooltip-view bottom :content="item.comment">
                    <template v-slot:action>
                      <v-icon small color="grey" class="mr-2">mdi-comment-text-outline</v-icon>
                    </template>
                  </tooltip-view>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
          <template v-slot:selection="{item}">{{item.name}}</template>
        </autocomplete-single>
        <autocomplete-single name="Результат" :items="callTarget===null ? [] : callTarget.callResults" @update:select="callResultUpdate" :disabled="callTarget===null" :select.sync="callResult">
          <template v-slot:no-data>
            <div class="caption text-center">Не найдено</div>
          </template>
          <template v-slot:itemIcon="{item}">
            <v-avatar v-if="item.type==='success'" color="success" size="16" class="mr-2"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
            <v-avatar v-else-if="item.type==='info'" color="blue" size="16" class="mr-2"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
            <v-avatar v-else color="red" size="16" class="mr-2"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
          </template>
          <template v-slot:selectedInfo="{item}">
            <v-avatar v-if="item.type==='success'" color="success" size="16" style="margin-top:-10px" class="mr-2"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
            <v-avatar v-else-if="item.type==='info'" color="blue" size="16" style="margin-top:-2px"  class="mr-2"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
            <v-avatar v-else color="red" size="16" class="mr-2"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
            <span class="text-no-wrap">{{item.name}}</span>
          </template>
        </autocomplete-single>
      </div>
      <field-view
          :key="localCallGroup.comment"
          label="Комментарий" :value="localCallGroup.comment" @updated="$emit('change')"
          field="comment" type="textarea"
          :url="'/api/callGroup/'+localCallGroup.id+'/'"
      />
      <call-record-player :callGroup="localCallGroup" full/>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import TooltipView from "@/components/TooltipView.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import FieldView from "@/components/FieldView.vue";
import CallRecordPlayer from "@/views/Call/CallRecordPlayer.vue";
import {Entity_User, Entity_UserProfile} from "../../../EntityStoreCacheService";

export default {
  components: {CallRecordPlayer, FieldView, UserAutocompleteSingle, TooltipView,  AutocompleteSingle},
  props:['callGroup', 'callTargets'],
  data: () => ({
    localCallGroup:null,
    callType:0,
    callClientType:0,
    userClientRecoms:[],

    callTarget:null,
    callResult:null,
  }),
  mounted() {
    this.init();
  },
  watch:{
    callGroup(){
      this.init();
    },
    localCallGroup(){
      this.$emit('update:callGroup', this.localCallGroup)
    }
  },
  methods: {
    init(){
      this.localCallGroup = this.callGroup;
      this.callType = this.localCallGroup.userClientState===null && this.localCallGroup.userClient===null ? 1 : 0;
      if(this.localCallGroup.userClientState==='second') this.callClientType = 1;
      if(this.localCallGroup.userClientState==='first') this.callClientType = 0;
      if(this.localCallGroup.userClient!==null) this.callClientType = 2;
      if(this.localCallGroup.callTarget===null){
        this.callTarget = null;
      } else {
        this.callTargets.forEach(v=>{
          if(this.localCallGroup.callTarget.id===v.id)
            this.callTarget = v;
        })
      }
      if(this.localCallGroup.callResult===null){
        this.callResult = null;
      } else {
        this.callTargets.forEach(v=>{
          v.callResults.forEach(v1=>{
            if(this.localCallGroup.callResult.id===v1.id)
              this.callResult = v1;
          })
        })
      }


      this.userClientRecoms = [];
      let phone = null;
      if (this.localCallGroup.callDirection===1)
        phone = this.localCallGroup.fromPhoneNumber;
      else if (this.localCallGroup.callDirection===2)
        phone = this.localCallGroup.toPhoneNumber;
      if(phone!==null){
        new Entity_User()
            .selects([Entity_User.id].join('.'))
            .selects([Entity_User.type].join('.'))
            .selects([Entity_User.firstName].join('.'))
            .selects([Entity_User.lastName].join('.'))
            .selects([Entity_User.secondName].join('.'))
            .selects([Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
            .selects([Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
            .filterAnd(
                [Entity_User.entity, Entity_User.phone].join('.')+'=? OR '+
                [Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.phone1].join('.')+'=?',
                phone,
                phone,
            )
            .addStateCallback((v)=>{
              if(v.success) {
                this.userClientRecoms = v.items
                this.userClientRecoms.forEach((v)=>{
                  v.name = v.lastName+' '+v.firstName+' '+v.secondName;
                })
              }
            })
            .request()
      }
    },
    userClientUpdate(v){
      if(this.localCallGroup.userClient?.id===v?.id) return null;
      axios.put(
          '/api/callGroup/'+this.localCallGroup.id+'/',
          {userClient:v===null ? null : v.id},
          {withCredentials: true}
      ).then(()=>{
        this.localCallGroup.userClient = v;
        this.$emit('change')
      })
    },
    callTargetUpdate(v){
      if(this.localCallGroup.callTarget?.id===v?.id) return null;
      if(v===null){
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {callTarget:null, callResult:null},
            {withCredentials: true}
        ).then(()=>{
          this.$emit('change')
        })
      } else {
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {callTarget:v.id},
            {withCredentials: true}
        ).then(()=>{
          this.$emit('change')
        })
      }
    },
    callResultUpdate(v){
      if(this.localCallGroup.callResult?.id===v?.id) return null;
      axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
          {callResult:v===null ? null : v.id},
          {withCredentials: true}
      ).then(()=>{
        this.$emit('change')
      })
    },
    callTypeChange(){
      if (this.callType===0){
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {userClientState:'first',userClient:null,callResult:null,callTarget:null},
            {withCredentials: true}
        ).then(()=>{
          this.localCallGroup.userClientState = 'first';
          this.localCallGroup.userClient = null;
          this.localCallGroup.callTarget = null;
          this.localCallGroup.callResult = null;
          this.$emit('change')
        })
      } else {
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {userClientState:null,userClient:null,callResult:null,callTarget:null},
            {withCredentials: true}
        ).then(()=>{
          this.localCallGroup.userClientState = null;
          this.localCallGroup.userClient = null;
          this.localCallGroup.callTarget = null;
          this.localCallGroup.callResult = null;
          this.$emit('change')
        })
      }
    },
    callClientTypeChange(){
      if(this.callClientType===0){
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {userClientState:'first',userClient:null},
            {withCredentials: true}
        ).then(()=>{
          this.localCallGroup.userClientState = 'first';
          this.localCallGroup.userClient = null;
          this.$emit('change')
        })
      } else if(this.callClientType===1){
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {userClientState:'second',userClient:null},
            {withCredentials: true}
        ).then(()=>{
          this.localCallGroup.userClientState = 'second';
          this.localCallGroup.userClient = null;
          this.$emit('change')
        })
      } else if(this.callClientType===2){
        axios.put('/api/callGroup/'+this.localCallGroup.id+'/',
            {userClientState:null,userClient:null},
            {withCredentials: true}
        ).then(()=>{
          this.localCallGroup.userClientState = null;
          this.localCallGroup.userClient = null;
          this.$emit('change')
        })
      }
    },
    visitLink(){
      if(this.localCallGroup.userClient===null){
        if(this.localCallGroup.callDirection===0)
          return '';
        else if (this.localCallGroup.callDirection===1)
          return 'phone='+this.localCallGroup.fromPhoneNumber+'&callGroupId='+this.localCallGroup.id;
        else if (this.localCallGroup.callDirection===2)
          return 'phone='+this.localCallGroup.toPhoneNumber+'&callGroupId='+this.localCallGroup.id;
      } else {
        return 'userId='+this.localCallGroup.userClient.id+'&callGroupId='+this.localCallGroup.id
      }
    }
  }
}
</script>

